<template>
    <div class="text_img_main" :class="'type_'+type" :id="uid">
        <div class="img_warp" >
            <div class="img_box">
<!--              v-if="loadImg"-->
                <bgimg :bgcolor="bgcolor" type="100"  @load="load" :src="src"></bgimg>
            </div>
            <div class="title_box">
                <h1 class="title_text">{{title}}</h1>
                <div class="content_text">{{subtitle}}</div>
            </div>
            <div class="download_box">
             <div  class="download_box_content">
               <v-btn
                   class="ma-2"
                   outlined
                   color="white"
                   @click="downloadImg(item,'1920x1080')"
               >
                 下载 1920x1080
               </v-btn>
             </div>
              <div  class="download_box_content">
                <v-btn
                    class="ma-2"
                    outlined
                    color="white"
                    @click="downloadImg(item,'UHD')"
                >
                  下载 UHD
                </v-btn>
              </div>
            </div>
        </div>
    </div>
</template>

<script>
    import Bgimg from "@/components/iImage/bgimg/bgimg";
    function  generateUUID() {
        return  'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g,  function(c) {
            let  r = Math.random()*16|0, v = c ==  'x'  ? r : (r&0x3|0x8);
            return  v.toString(16);
        })
    }
    export default {
        name: "text_img",
      components: {Bgimg},
      props:['item', 'src','scrollTop','ScrollHeight','lazy','bgcolor','type','title','subtitle'],
        data(){
            return{
                loadImg:false,
                uid:'',
                viewHeight:0,
            }
        },
        mounted() {
            let that=this;
            this.loadImg=this.lazy!=undefined?(!this.lazy):true;
            this.viewHeight= document.documentElement.clientHeight;
        },
        watch:{
            scrollTop(nval){
                this.onScroll(nval)
            },
            ScrollHeight(nval,oval){
                this.viewHeight=nval
            }
        },
        methods:{
            downloadImg(item, type){
              this.$emit('download', item, type)
            },
            load(data){
                // this.height=heigth;
            },
            init(){
                this.uid = 'uid-' + generateUUID();
                this.$nextTick(this.onScroll)
            },
            onScroll(scrollTop){
                // 加载ing时才执行滚动监听判断是否可加载
                if(this.loadImg) return;
                const id = this.uid
              document.querySelector.select('#'+id).boundingClientRect(data => {
                    if(!data) return;
                    if(data.top - this.viewHeight<0){
                        this.loadImg = true;
                    }
                }).exec()
            },
        }
    }
</script>

<style lang="scss" scoped>

.text_img_main{
    font-size: 0px;
    position: relative;
    transition: 1s all;
    .img_warp{
        width: 100%;
        height: 100%;
        position: absolute;

        top: 0px;
        left: 0px;
        overflow: hidden;
        .img_box{
            position: absolute;
            transition: 1s;
            top: 0px;
            cursor: pointer;
            left: 0px;
            width: 100%;
            height: 100%;
            .img{
                width: 100%;
                height: 100%;
                position: absolute;

            }
        }
        .img_box:hover{
            transform: scale(1.02);
        }
        .loding{
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: 1;
            display: flex;
            justify-content: center;
            align-items: center;
            background-color: #999999;
            top:0px;
            left: 0px;
        }
      .download_box{
        z-index: 2;
        position: absolute;
        bottom: 15px;
        overflow: hidden;
        transition: 1s all;
        //background: #666666;
        min-height: 0;


        .download_box_content{
          padding: 0 5px 0 5px;
          //display: inline-block;
          min-width: 50px;
          font-size: 12px;
          font-weight: 400;
          letter-spacing: .15em;
          text-transform: uppercase;
          line-height: 1.18;
          color: #fff;
        }
      }
        .title_box{
            z-index: 2;
            position: absolute;
            margin-top: 90px;
            padding: 19px 25px 14px 15px;
            overflow: hidden;
            transition: 1s all;

          background: rgb(228 230 235 / 80%);
            min-height: 0;
            .title_text{
                font-size: 18px;
                font-weight: 700;
                letter-spacing: .093em;
                text-transform: uppercase;
                line-height: 1;
                padding-bottom: 5px;
            }
            .content_text{
                font-size: 12px;
                font-weight: 400;
                letter-spacing: .15em;
                text-transform: uppercase;
                line-height: 1.18;
                color: #666666;
            }
        }

    }

    .img_warp:hover{

        .img_box{
            transform: scale(1.05);
        }
        .title_box{
            transition: 1s all;
            padding: 19px 25px 14px 30px;
        }
    }

}

@media screen and (max-width: 900px) {
    .text_img_main{
        .img_warp{
            .title_box{
                margin-top: 30px;
                padding-top: 15px;
                padding-bottom: 9px;
                .title_text{
                    font-size: 12px;
                    font-weight: 700;
                    letter-spacing: .093em;
                    text-transform: uppercase;
                    line-height: 1;

                    position: relative;
                    padding-left: 15px;
                }
                .content_text{
                    display: none;
                }
            }
        }
    }
}
.type_100{
    width: 100%;
    padding-top: 45%;
}

.type_30{
    width: 100%;
    padding-top: 80%;
}

.type_50{
    width: 100%;
    padding-top: 83%;
}

    @keyframes text_anm {
        0%{
          padding: 0;
            opacity: 0;
        }
        100%{
            padding: 10px 5px 0;
            opacity: 1;
        }
    }

</style>