<template>
    <!--text_img使用的组件-->
    <div class="img_main" :style="{backgroundColor:bgcolor}" >
        <div v-if="!hidden" class="bgimg_box"  :style="{background:'url('+src+')',backgroundSize:'cover',backgroundPosition:'center center'}" />
<!--        <div v-else class="loding">-->
<!--            <u-loading slot="loading"></u-loading>-->
<!--        </div>-->
    </div>
</template>

<script>

    export default {
        name: "bgimg",
        props:['src','bgcolor'],
        data(){
            return {
                hidden:false,
            }
        },
        mounted() {
            // let that=this;
            // uni.getImageInfo({src:this.src,success:result => {
            //             that.$emit('load',result);
            //             that.hidden=false;
            //     }})
        },
    }
</script>

<style lang="scss" scoped>
.img_main{
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    animation: img_anm 2s ;
    @keyframes img_anm {
        0%{
            opacity: 0;

            transform: translateY(50px);
        }
        50%{ transform: translateY(0);}
        100%{
            opacity: 1;

        }
    }
    background: rgba(0,0,0,0.6);
    position: relative;
    .bgimg_box{
        position: absolute;
        top: 0px;
        z-index: 2;
        margin: 0px;
        width: 100%;
        height: 100%;
    }
    .loding{
        z-index: 1;
        position: relative;
    }

}

</style>