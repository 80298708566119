<template>
  <v-app id="inspire">
<!--    <v-app-bar-->
<!--        app-->
<!--        absolute-->
<!--        hide-on-scroll-->
<!--        scroll-target="#scrolling-techniques-4"-->
<!--    >-->
<!--&lt;!&ndash;      <v-app-bar-nav-icon></v-app-bar-nav-icon>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-toolbar-title>i Bing壁纸</v-toolbar-title>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-spacer></v-spacer>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-btn icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <v-icon>mdi-magnify</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      </v-btn>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-btn icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <v-icon>mdi-heart</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      </v-btn>&ndash;&gt;-->

<!--&lt;!&ndash;      <v-btn icon>&ndash;&gt;-->
<!--&lt;!&ndash;        <v-icon>mdi-dots-vertical</v-icon>&ndash;&gt;-->
<!--&lt;!&ndash;      </v-btn>&ndash;&gt;-->

<!--    </v-app-bar>-->

    <v-main>
      <v-container fluid>
        <router-view></router-view>
      </v-container>
    </v-main>
<!--    <v-footer   elevation="24" padless>-->
<!--      <div style="display: flex; flex-direction: column;align-items: center; justify-content: center;width: 100%;padding: 5px 0">-->
<!--        <span style="color: #838383">本站所有图片都来自于必应 ©2022 - 2022 By i吧啦吧啦 <a target="_blank" style="color: #838383" href="https://beian.miit.gov.cn/">豫ICP备18029750号-3</a></span>-->
<!--      </div>-->
<!--    </v-footer>-->
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>
<style>
body{
  background-color: #eaeaea;
  overflow:  hidden;
}
</style>