<template>
  <div class="main" @scroll="scrollTop">

    <div  class="grid">
      <v-snackbar
          v-model="snackbar"
          timeout="-1"
          bottom
      >
        下载中
        <template v-slot:action="{ attrs }">
          <v-btn
              color="indigo"
              text
              v-bind="attrs"
              @click="snackbar = false"
          >
            Close
          </v-btn>
        </template>
      </v-snackbar>
<!--      <div class="column" :class="'column_'+item.type" v-for="(item,i) in allData">-->
<!--        <div v-for="(img,j)  in item.array" class="img_grid" >-->
<!--          <text_img :lazy="true" @download="download" :scroll-top="scrolltop" :subtitle="img.copyright" :item="img"  :title="img.title"  :type="item.type" :src="img.hostBase" />-->
<!--        </div>-->
<!--      </div>-->
      <div class="column" :class="'column_30'" v-for="(item,i) in allData">
        <div v-for="(img,j)  in item.array" class="img_grid" >
          <text_img :lazy="true" @download="download" :scroll-top="scrolltop" :subtitle="img.copyright" :item="img"  :title="img.title"  :type="30" :src="img.hostBase" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bgimg from "@/components/iImage/bgimg/bgimg";
import {reqGetDailyPhoto} from "@/api/home";
import Text_img from "@/components/iImage/text_img/text_img";
export default {
  name: "Test",
  components: {Text_img, Bgimg},
  data(){
    return{
      snackbar: false,
      scrolltop:0,
      dataList: [],
      allData:[]
    }
  },
  mounted() {

    reqGetDailyPhoto({
      "page": {
        "current": 1,
        "size": 100,
      },
    }).then(res=>{
      if(res.code === 0){
        this.dataList = res.data.records
        this.hanlData(res.data.records,this.allData, 2);
      }
      console.log(this.dataList)
    })
  },
  methods:{
    download(item,  type){
      this.snackbar = true
      console.log("item", item)
      console.log("type", type)
      this.downloadImg('https://www.bing.com'+item.urlbase+'_'+type+".jpg", item.title+".jpg")
    },
    async downloadImg(url,name) {
      await fetch(url).then(res => res.blob().then(blob => {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(blob);
        var filename = name;
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);

      }));
      this.snackbar = false
    },
    hanlData(imgs,setarray,randomIndex=null){
      let max=2;
      let random=0;
      let j=random;
      let types=[100,50,30];
      for(let i=0;i<imgs.length;){
        random=randomIndex?randomIndex:parseInt(Math.random()*(max+1),10);
        let a= {type:types[random],array:[]};
        for (let j=random;j>=0&&i<imgs.length;j--){
          a.array.push(imgs[i]);
          i++;
        }
        setarray.push(a);
      }
      this.setEnd(setarray);
    },
    setEnd(setarray){
      let types=[0,100,50,30];
      setarray[setarray.length-1].type=types[setarray[setarray.length-1].array.length];
    },
    scrollTop(e){
      console.log(e)
      let scroll= document.getElementsByClassName("main")[0].scrollTop;
      this.scrolltop=scroll;
      if(scroll>255){
        this.showfiltertop=true;
      }else {
        if( this.label){
          return
        }
        this.showfiltertop=false;
        this.showfilterhead=false;
      }
    },
  }
}
</script>

<style lang="scss" scoped>
.work_main {
  width: 100%;
  height: 100%;
  //background: $bgcolor;
  overflow-y: scroll;
  position: sticky;
}
@media screen and (min-width: 900px){
  .grid{
    padding-top: 13px;
    width: auto;
    margin: 0 15px;
    overflow: hidden;

    .column{
      display: flex;
      justify-content: left;
      width: 100%;
      margin-bottom: 15px;
    }
    .column_100{
      .img_grid{
        width: 100%;
      }

    }
    .column_30{
      width: 100%;
      display: grid;

      grid-template-columns: 33.3% 33.3% 33.3%;
      grid-template-rows: auto;
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      .img_grid{
        width: 100%;
      }

    }
    .column_50{
      display: grid;
      width: 100%;
      grid-template-columns: 50% 50%;
      grid-template-rows: auto;
      grid-row-gap: 15px;
      grid-column-gap: 15px;
      .img_grid{
        width:100%;
      }
    }
  }
}
@media screen and (max-width: 900px){
  .grid{
    padding-top: 13px;
    width: 100%;
    overflow: hidden;
    .column{
      display: flex;
      justify-content: left;
      width: 100%;
      flex-direction: column;
      .img_grid{
        margin-bottom: 10px;
        width: 100%;
      }
    }
  }
}
</style>