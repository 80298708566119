<template>
  <div class="container">
    <div id="slide">
      <div class="item item-nth-child" style="background-image: url(https://minio.ibalbal.com:800/wx-mini/ylong.png)">
        <div class="content">
          <div class="name">i Bing壁纸</div>
          <div class="des">本站所有图片都来自于<a href="https://cn.bing.com"  target="_blank" >必应</a></div>
          <div class="des">©2022 - 2023 By i吧啦吧啦</div>
<!--          <button>See more</button>-->
        </div>
      </div>
      <div   v-for="(item,index) in dataList" :key="index"
             :class="{
              'item-nth-child':activeIndex-1===index,
              'item-nth-child-0':activeIndex===index,
              'item-nth-child-1':activeIndex+1===index,
              'item-nth-child-2':activeIndex+2===index,
              'item-nth-child-3':activeIndex+3===index,
              'item-nth-child-4':activeIndex+4===index,
              'item-nth-child-n': index> activeIndex+4
            }"
             class="item"  :style="'background-image: url('+'https://www.bing.com'+item.urlbase+'_1920x1080.jpg)'">
        <div class="content">
          <div class="name">{{item.title}}</div>
          <div class="des">{{item.copyright}}</div>
          <div class="download_box">
            <div  class="download_box_content">
              <v-btn
                  class="ma-2"
                  outlined
                  color="white"
                  @click="download(item,'1920x1080')"
              >
                下载 1920x1080
              </v-btn>
            </div>
            <div  class="download_box_content">
              <v-btn
                  class="ma-2"
                  outlined
                  color="white"
                  @click="download(item,'UHD')"
              >
                下载 UHD
              </v-btn>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="buttons">
      <div v-show="activeIndex!=0" @click="previous" class="s_button">&lt;</div>
      <div v-show="total!=dataList.length" @click="next" class="s_button">&gt;</div>
    </div>
    <v-snackbar
        v-model="snackbar"
        timeout="-1"
        bottom
    >
      下载中
      <template v-slot:action="{ attrs }">
        <v-btn
            color="indigo"
            text
            v-bind="attrs"
            @click="snackbar = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import {reqGetDailyPhoto} from "@/api/home";

export default {
  name: "IndexView",
  data(){
    return{
      activeIndex:1,

      //定时器
      timer:0,
      //轮播时间
      timeout: 8000,
      //轮播方向 默认下一张
      direction: true,

      //是否还存在数据
      noMore: false,
      //是否加载中
      loadingMore: false,

      //分页防抖
      reqCount:0,
      //搜索内容
      search:{
        //分页体
        page:{
          size: 10,
          current: 1,
        },
      },
      //总数
      total:0,
      //获取的数据
      dataList:[
        {
          urlbase:"https://minio.ibalbal.com:800/wx-mini/ylong.png",
          title:"i Bing壁纸",
          copyright:"本站所有图片都来自于必应"
        }
      ],
      snackbar: false,
    }
  },

  watch:{
    activeIndex(){
      console.log(Number(this.activeIndex + 5) >= Number(this.dataList.length))
      if (Number(this.activeIndex + 5) >= Number(this.dataList.length) && !this.noMore){
        this.reqSelectList()
      }
    }
  },
  mounted() {
    this.$nextTick(()=>{
      this.reqSelectList(()=>{
        // if (this.timer){
        //   clearInterval(this.timer)
        // }
        // this.timer = setInterval(()=>{
        //   this.move()
        // }, this.timeout)
        // this.mouseoverEventListenerStart()
        // this.mouseoverEventListenerStop()
      })
    })
  },
  methods:{
    async reqSelectList(callback){
      //分页防抖
      if (this.reqCount > 0) return
      this.reqCount += 1
      this.loadingMore = true
      if(!this.noMore){
        await reqGetDailyPhoto(this.search).then(res=>{
          res = res.data
          let list = this.dataList
          list = list.concat(res.records)
          this.dataList = list
          if(callback)callback()
          this.total = res.total
          this.noMore = !this.isLastPage(res.records.length, this.search.page.current, this.search.page.size, res.total)
          this.search.page.current += 1
        }).finally(()=>{
          this.loadingMore = false
          //分页防抖
          this.reqCount -= 1
        })
      }
      this.loadingMore = false
    },
    download(item,  type){
      this.snackbar = true
      this.downloadImg('https://www.bing.com'+item.urlbase+'_'+type+".jpg", item.title+".jpg")
    },
    async downloadImg(url,name) {
      await fetch(url).then(res => res.blob().then(blob => {
        var a = document.createElement('a');
        var url = window.URL.createObjectURL(blob);
        var filename = name;
        a.href = url;
        a.download = filename;
        a.click();
        window.URL.revokeObjectURL(url);
      }));
      this.snackbar = false
    },
    isLastPage (row,pageNum,pageSize,total){
      if(pageNum === 1){
        return total > pageSize
      }
      return total > ((pageNum-1)*pageSize + row)
    },
    //监听鼠标移出 播放
    mouseoverEventListenerStart(){
      document.querySelector(".container").addEventListener("mouseout", ()=>{
        this.timer = setInterval(()=>{
          if (this.activeIndex != 0){
            this.move()
          }
        }, this.timeout)
      })
    },
    //监听鼠标移入 暂停
    mouseoverEventListenerStop(){
      document.querySelector(".container").addEventListener("mouseover", ()=>{
        clearInterval(this.timer)
      })
    },

    //移动
    move(){
      //下一张
      if(this.direction){
        if(this.activeIndex!= this.total){
          ++ this.activeIndex
        }
      }else {
        if(this.activeIndex != 0){
          -- this.activeIndex
        }
      }
    },
    //上一张
    previous(){
      this.direction = false
      this.move()
    },
    //下一张
    next(){
      this.direction = true
      this.move()
    }
  }
}
</script>

<style scoped lang="scss">

.container{
  width: 100%;
  height: 100%;
  background-color: #f5f5f5;
  padding: 50px;
  box-shadow: 0 30px 50px #dbdbdb;
}
#slide{
  /*宽度等于里面内容的宽度*/
  width: max-content;
  margin-top: 50px;
}
.item{
  width: 200px;
  height: 300px;
  /*图片中心*/
  background-position: 50% 50%;
  background-size: cover;
  display: inline-block;
  /* 重叠*/
  position: absolute;
  top: 50%;
  border-radius: 20px;
  box-shadow: 0  30px 50px #505050;
  transition: .5s
  /*transition: .5s cubic-bezier(0.3, 1.7, 0.5, 1.2);*/
  /*transition: .5s ease-in;*/
}
.item-nth-child,
.item-nth-child-0{
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin-top: 0;
  border-radius: 0;
  box-shadow: none;
}
.item-nth-child-1{
  left: 50%;
}
.item-nth-child-2{
  left: calc(50% + 220px);
}
.item-nth-child-3{
  left: calc(50% + 440px);
}
.item-nth-child-4{
  left: calc(50% + 660px);
}
.item-nth-child-n{
  left: calc(50% + 880px);
  opacity: 0;
}
/*.item:nth-child(1),*/
/*.item:nth-child(2){*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  margin-top: 0;*/
/*  border-radius: 0;*/
/*  box-shadow: none;*/
/*}*/
/*.item:nth-child(3){*/
/*  left: 50%;*/
/*}*/
/*.item:nth-child(4){*/
/*  left: calc(50% + 220px);*/
/*}*/
/*.item:nth-child(5){*/
/*  left: calc(50% + 440px);*/
/*}*/
/*.item:nth-child(n+6){*/
/*  left: calc(50% + 660px);*/
/*  opacity: 0;*/
/*}*/

.item .content{
  /*width: 300px;*/
  position: absolute;
  left: 100px;
  top: 20%;
  /*transform: translateY(-50%);*/
  font-family: system-ui;
  color: #eee;
  display: none;
}
.item-nth-child-0 .content{
  display: block;
}
.item-nth-child-0 .name{
  font-size: 40px;
  font-weight: bolder;
  opacity: 0;
  animation: showContent 1s ease-in-out 1 forwards;

}
.item-nth-child-0 .des{
  margin: 20px 0;
  opacity: 0;
  animation: showContent 1s ease-in-out 0.3s forwards;

}

.item-nth-child-0 button{
  padding: 10px 20px;
  border: none;
  opacity: 0;
  animation: showContent 1s ease-in-out 0.6s forwards;
}

@keyframes showContent {
  from{
    opacity: 0;
    transform: translateY(100px);
    /*滤镜*/
    filter: blur(20px);
  }to{
    opacity: 1;
       transform: translateY(0);
       /*滤镜*/
       filter: blur(0);
  }
}

.buttons{
  width: 100%;
  position: absolute;
  bottom: 50px;
  margin-left: -50px;
  text-align: center;
}
.s_button{
  display: inline-block;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  color: gray;
  background-color: white;
  font-size: 25px;
  border-radius: 50%;
  font-weight: bolder;
  border:  1px solid gray;
  margin: 0 25px;
  /*0.5s的过度时间*/
  transition:  .5s;
}
.s_button:hover{
  color: gray;
  cursor: pointer;
  /*0.5s的过度时间*/
  transition:  .5s;
}
.download_box{
  transition: 1s all;
  min-height: 0;
  .download_box_content{
    display: inline-block;
    min-width: 50px;
    font-size: 12px;
    font-weight: 400;
    text-transform: uppercase;
    line-height: 1.18;
    color: #fff;
  }
}


/*选中前两个item
铺满整个容器
*/
/*.item:nth-child(1),*/
/*.item:nth-child(2){*/
/*  top: 0;*/
/*  left: 0;*/
/*  width: 100%;*/
/*  height: 100%;*/
/*  transform: translateY(0);*/
/*  border-radius: 0;*/
/*  box-shadow: none;*/
/*}*/
/*.item:nth-child(3){*/
/*  left: 50%;*/
/*}*/
/*.item:nth-child(4){*/
/*  left: calc(50% + 220px);*/
/*}*/
/*.item:nth-child(5){*/
/*  left: calc(50% + 440px);*/
/*}*/
/*.item:nth-child(n+6){*/
/*  left: calc(50% + 660px);*/
/*  opacity: 0;*/
/*}*/

</style>
